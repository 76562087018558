import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const ProjectHelse = ({ data, location }) => {
  return (
    <Layout>
      <SEO title="Diseño web y UX" description="Colaboramos con las mejores agencias de diseño y UX para darle a nuestros sitios web el diseño visual y la usabilidad adecuadas a tus necesidades." />
  <section className="jumbotron bg-transparent pb-6" id="header">
    <div className="container">
      <div className="row align-items-center text-center">
        <h1 className="font-weight-bold display-4 pl-0 p-1 col-md-9 offset-md-2 col-sm-12">Diseño y Usabilidad Web.</h1>
        <p className="lead mb-4 col-md-9 offset-md-2 col-sm-12">Colaboramos con distintos profesionales especializados en Diseño Visual y en UX, elegimos al equipo que mejor se adapte a tu proyecto.</p>
      </div>
    </div>
  </section>
  <section className=" pb-5 pt-5 bg-light text-left" id="features">
    <div className="container">
      <div className="row text-left">
        <div className="col col-md-9 offset-md-2 col-sm-12">
          <h2>
            <strong className="text-left">Diseño Visual</strong>
          </h2>
          <p className="lead pt-4">Trabajamos con uno de los mejores estudios de Diseño Visual de Zaragoza, que ofrece los siguientes servicios.</p>
          <h3 className="pt-3">Naming e identidad verbal.</h3>
          <h3 className="pt-2">Identidad visual</h3>
          <ul className="lead">
            <li>Sistema visual</li>
            <li>Arquitectura de marca</li>
            <li>Aplicaciones</li>
            <li>Documento de marca</li>
          </ul>
          <h3 className="pt-2">Manual de identidad corporativo</h3>
          <h3 className="pt-2">Dirección de arte en fotografía y video</h3>
          <h3 className="pt-2">Diseño web</h3>
          <ul className="lead">
            <li>Mapa de secciones y wireframes</li>
            <li>Plantillas de contenido</li>
            <li className="pb-5">Diseño front-end</li>
          </ul>
        </div>
      </div>
      <div className="row text-left">
        <div className="col col-md-9 offset-md-2 col-sm-12">
          <h2>
            <strong className="text-left pt-0">Usabilidad web - UX</strong>
          </h2>
          <p className="lead pt-4">A través de nuestros colaboradores especialistas en UX podemos abarcar los siguientes servicios:</p>
          <h3 className="pt-3">Definición de objetivos</h3>
          <h3 className="pt-2">Necesidades de usuario</h3>
          <ul className="lead">
            <li>Focus group</li>
            <li>Observación</li>
            <li>Encuestas</li>
            <li>Entrevistas</li>
            <li>Definición de personas</li>
          </ul>
          <h3 className="pt-2">User journey</h3>
          <h3 className="pt-2">User testing</h3>
          <h3 className="pt-2">Estudio de mercado</h3>
          <h3 className="pt-2">Arquitectura de la información</h3>
        </div>
      </div>
    </div>
  </section>

    </Layout>
  )
}

export default ProjectHelse
